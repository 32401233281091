<template>
  <div v-loading="isLoading" class="px-8 relative fs-18">
    <div data-v-07957022 id="abstractContainer">
      <!---->
      <h1>{{ abstractData.title }}</h1>
      <div
        v-for="(citation,index) in abstractData.citations"
        :key="index"
        data-v-07957022
        class="abstract"
      >
        <div data-v-07957022 id="fullAbstract">
          <dl data-v-07957022>
            <dt data-v-07957022>
              <strong data-v-07957022>1</strong>
            </dt>
            <dd data-v-07957022>
              <span data-v-07957022 class="abstractLinks">
                <!---->
                <a data-v-07957022 :href="citation.pubmedHref" target="_blank" rel="noopener">PubMed</a>
                <!---->
                <span data-v-07957022 class="bar">|</span>
              </span>
            </dd>
            <!---->
            <dt data-v-07957022>TI</dt>
            <dd
              data-v-07957022
              v-if="citation.abstractInfo"
              class="abstractTitle"
            >{{ citation.abstractInfo.title }}</dd>
            <dt data-v-07957022>AU</dt>
            <dd v-if="citation.abstractInfo" data-v-07957022 class="abstractAuthor">
              <span data-v-07957022>{{ citation.abstractInfo.authors }}</span>&nbsp;
            </dd>
            <dt data-v-07957022>SO</dt>
            <dd v-if="citation.abstractInfo" data-v-07957022 class="abstractSource">
              <span data-v-07957022>{{ citation.abstractInfo.source }}</span>&nbsp;
            </dd>
            <dt data-v-07957022>&nbsp;</dt>
            <dd
              v-for="(abstractText,abstractIndex) in citation.abstractTexts"
              :key="`abstract-text-${abstractIndex}`"
              data-v-07957022
              class="abstractTexts"
            >
              <p data-v-07957022 class="abstractNames">
                <!---->
                <span data-v-07957022>{{ abstractText.text }}</span>
              </p>
            </dd>
            <dt data-v-07957022 class="abstractAffiliation">AD</dt>
            <dd v-if="citation.abstractInfo" data-v-07957022 class="abstractAffiliation">
              <span data-v-07957022>{{ citation.abstractInfo.affiliation }}</span>&nbsp;
            </dd>
            <dt data-v-07957022>PMID</dt>
            <dd v-if="citation.abstractInfo" data-v-07957022>
              <a
                data-v-07957022
                :href="citation.pubmedHref"
                target="_blank"
                rel="noopener"
              >{{ citation.abstractInfo.pmid }}</a>
            </dd>
            <!---->
          </dl>
        </div>
        <!---->
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: 'UptodateAbstract',
  data () {
    return {
      abstractData: {},
      content: '',
      isLoading: false
    }
  },
  created () {
    this.handleGetAbstract()
  },

  methods: {
    async handleGetAbstract () {
      try {
        this.isLoading = true
        const objQuery = this.$route.query

        const params = {
          slug: objQuery.slug,
          citation_range: objQuery.citation_range
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getUptodateAbstract(params)

        this.abstractData = this.handleFormatAbstract(response.data.data || {})
      } catch (error) {
        console.log(error)
        self.$toast.open({
          message: this.$t('Có lỗi xảy ra khi lấy dữ liệu'),
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleFormatAbstract (data = {}) {
      const title = `Medline ® Abstracts for References ${
        data.citationRange
      } of ${data.topicInfo?.title || ''}`

      const newCitations = data?.citations?.map(item => {
        const pubmed = item?.links?.[0]?.url
        const pubmedHref = `https://www.uptodate.com${pubmed}`

        return {
          ...item,
          pubmedHref
        }
      })

      return { ...data, title, citations: newCitations }
    }
  }
}
</script>
  
  <style lang="scss" scoped>
</style>